<template>
  <div class="sidebar-menu">
    <!-- BEGIN SIDEBAR MENU ITEMS-->
    <ul class="menu-items">
      <li v-for="item in items"
          :class="[item.boundaryClass, item.isActive ? (item.hasChild ? 'open active' : 'open active non-parent-active') : '']">
        <template v-if="item.clickHandle">
          <a href="#" @click.prevent="item.clickHandle">
            <span class="title app-text-bold">{{ item.label }}</span>
          </a>
          <span class="icon-thumbnail-img">
            <img :src="item.iconSrc"/>
           </span>
        </template>
        <template v-else-if="item.hasChild">
          <a href="javascript:;">
            <span class="title app-text-bold">{{ item.label }}</span>
            <span class="arrow"></span>
          </a>
          <span class="icon-thumbnail-img">
            <img :src="item.iconSrc"/>
           </span>
        </template>
        <template v-else>
          <router-link :to="{ name: item.routeName }">
            <span class="title app-text-bold">{{ item.label }}</span>
            <span v-if="item.hasChild" class=" arrow"></span>
          </router-link>
          <span :class="[item.isActive ? 'active ' : '', 'icon-thumbnail-img']">
            <img :src="item.iconSrc"/>
        </span>
        </template>
        <ul v-if="item.children && item.children.length" class="sub-menu">
          <li v-for="child in item.children" :class="[child.boundaryClass, child.isActive ? 'open active' : '']">
            <template v-if="child.clickHandle">
              <a href="#" @click.prevent="child.clickHandle">
                <span class="title app-text-bold">{{ child.label }}</span>
              </a>
              <span class="icon-thumbnail-img">
              <img :src="child.iconSrc"/>
             </span>
            </template>
            <template v-else>
              <router-link :to="{ name: child.routeName }">
                <span class="title app-text-bold">{{ child.label }}</span>
              </router-link>
              <span :class="'icon-thumbnail-img'">
            <img :src="child.iconSrc"/>
            </span>
            </template>
          </li>
        </ul>
      </li>
    </ul>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  props: {
    inputItems: Array
  },
  computed: {
    items() {
      var items = this.inputItems
      var newItems = [];
      _.forEach(items, (item) => {
        let hasChild = item.children && item.children.length > 0;
        let newItem = {
          ...item,
          hasChild,
          isActive: (item.activeKey === this.$route.meta.activeKey) && item.activeKey
        }

        if (hasChild) {
          _.forEach(item.children, (child, childIndex) => {
            let isActive = (child.activeKey && (child.activeKey === this.$route.meta.activeKey));
            if (!newItem.isActive) {
              newItem.isActive = isActive
            }
            newItem.children[childIndex].isActive = isActive
          })
        }
        newItems.push(newItem)
      })
      return newItems;
    }
  },
}
</script>
