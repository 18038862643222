<template>
  <div class="layout-container">
    <template v-if="role === 'admin'">
      <SidebarAdmin/>
    </template>
    <div class="page-container">
      <div class="page-content-wrapper">
        <div class="content">
          <div class="jumbotron header-jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-0 sm-p-r-0 bg-white breadcrumb-container">
              <div class="inner header-inner">
                <Breadcrumbs
                  :actions="actions"
                ></Breadcrumbs>
              </div>
            </div>
          </div>
          <div>
            <router-view :key="$route.path"></router-view>
          </div>
        </div>
      </div>
    </div>

    <Modal modal-class="slide-up modal_form" ref="modal_update_form" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="title">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)" @change="onChangeForm">
            <div class="modal-body">
              <AppInput
                :disabled="pass ? true : false"
                input-style="normal"
                name="name"
                :label="$t('users.name')"
                type="string"
                rules="required"
                v-model="entryToEdit.name"
              />

              <AppInput
                v-if="update"
                input-style="normal"
                name="email"
                :label="$t('users.email')"
                type="email"
                :rules="V_EMAIL_REQUIRED"
                v-model="entryToEdit.email"
                disabled="true"
              />

              <AppInput
                v-if="update"
                input-style="normal"
                name="phone"
                :label="$t('users.phone')"
                type="number-length:11"
                rules="required|phone"
                v-model="entryToEdit.phone"
              />

              <AppInput
                autocomplete="new-password"
                v-if="pass"
                input-style="normal"
                name="current_password"
                :label="$t('users.old')"
                type="password"
                rules="required"
                is_require_flag="true"
                v-model="changePass.old"
              />

              <AppInput
                v-if="pass"
                input-style="normal"
                name="new_password"
                :label="$t('users.new')"
                type="password"
                rules="required|required_if:current_password|password|min:8|max:20"
                :is_require_flag="true"
                v-model="changePass.new"
              />

              <AppInput
                v-if="pass"
                input-style="normal"
                name="new_password_confirm"
                :label="$t('users.new_confirm')"
                type="password"
                is_require_flag="true"
                rules="required|required_if:current_password|confirmed:new_password"
                v-model="changePass.new_confirm"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                @click="confirmClose"
              >
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('users.edit_users') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>

    <ConfirmModal
      ref="modal_delete"
      :modal-header-title="$t('common.confirm_cancel_title')"
      :confirm-handle="deleteEntrySubmit">
    </ConfirmModal>
  </div>
</template>

<script>
import Breadcrumbs from "@components/_partials/Breadcrumbs";
import SidebarAdmin from "@components/admin/_partials/Sidebar";

export default {
  data() {
    return {
      title: "",
      active: false,
      update: false,
      pass: false,
      changePass: {
        old: null,
        new: null,
        new_confirm: null,
      },
      entryToEdit: {},
      actions: {
        updateProfile: this.updateProfile,
        changePass: this.updatePass,
      },
      role: this.$store.state.auth.currentAuth.role
    }
  },
  components: {
    SidebarAdmin,
    Breadcrumbs,
  },
  methods: {
    deleteEntrySubmit()
    {
      this.$refs.modal_delete.hide();
      this.$refs.modal_update_form.hide();
    },
    confirmClose()
    {
      if (this.active == 1) {
        this.$refs.modal_delete.show();
      } else {
        this.$refs.modal_update_form.hide();
      }
    },
    onChangeForm()
    {
      this.active = true;
    },
    clearEntryToEdit()
    {
      this.$router.go();
      this.title = "";
      this.active = false;
      this.pass = false;
      this.update = false;
      this.$refs.form_update_entry.reset();
      this.changePass = {
        old: null,
        new: null,
        new_confirm: null,
      };
    },
    updatePass()
    {
      this.title = this.$t('common.change_pass');
      this.pass = true;
      this.changePass.old = "";
      this.$refs.modal_update_form.show();
    },
    updateProfile()
    {
      this.title = this.$t('users.update_profile');
      this.update = true;
      this.$refs.modal_update_form.show();
    },
    async updateEntry()
    {
      var res;

      if (this.update) {
        res = await this.$request.post(this.$consts.API.PROFILE.UPDATE_PROFILE, {
          name: this.entryToEdit.name,
          email: this.entryToEdit.email,
          phone: this.entryToEdit.phone,
        });
      } else if (this.pass) {
        res = await this.$request.post(this.$consts.API.PROFILE.UPDATE_PROFILE, {
          name: this.entryToEdit.name,
          email: this.entryToEdit.email,
          current_password: this.changePass.old,
          new_password: this.changePass.new,
          new_password_confirm: this.changePass.new_confirm,
        });
      }

      if (!res.hasErrors()) {
        if (this.pass) {
          this.changePass = {};
        }
        this.$appNotice.success(this.$t('common.msg update ok'));
        this.$refs.modal_update_form.hide();
        this.$auth.user().name = this.entryToEdit.name;
        this.$router.go();
      } else {
        if (res.data?.errors) {
            this.$refs.form_update_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    clickLogout() {
      this.logout();
    },
  },
  mounted() {
    this.$request.get(this.$consts.API.PROFILE.USER).then(res => {
      if (!res.hasErrors()) {
        this.entryToEdit = res.data.data;
        this.$auth.user().name = this.entryToEdit.name;
        localStorage.setItem('ID_ADMIN', this.entryToEdit.id);
      }
    })
    try {
      $.Pages.init();
      if ($('body').hasClass('sidebar-open')) {
        $('[data-pages="sidebar"]').addClass('visible')
      }
      $('body').on('submit', 'form', function () {
        $(this).find('input').blur();
      })
    } catch (e) {

    }
  },
}
</script>
<style type="text/css">
  .modal_form .close {
    display: none;
  }
  .breadcrumb-container .breadcrumb {
    text-transform: capitalize;
  }
</style>
