<template>
  <nav class="page-sidebar" data-pages="sidebar">
    <div class="sidebar-header text-center">
<!--      <img src="/images/kitagunitv_logo.png" alt="logo" data-src="/images/kitagunitv_logo.png" class="brand"-->
<!--           style="margin-top: -7px; width: 100%;object-fit: scale-down;"-->
<!--           data-src-retina="/images/kitagunitv_logo.png" height="50">-->
    </div>
    <AppSidebar :input-items="items"></AppSidebar>
  </nav>
</template>
<script>
import AppSidebar from "@components/_common/AppSidebar";

export default {
  components: {AppSidebar},
  data() {
    return {
      items: [
        // {
        //   routeName: this.$consts.ROUTES.ADMIN.DASHBOARD,
        //   label: this.$t('nav.dashboard'),
        //   activeKey: 'admin.dashboard'
        // },
        {
          routeName: this.$consts.ROUTES.ADMIN.BOOKINGS,
          label: this.$t('nav.bookings'),
          activeKey: 'admin.bookings'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.MEMBERS,
          label: this.$t('nav.members'),
          activeKey: 'admin.members'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.TOUR_ORDERS,
          label: this.$t('nav.tour_orders'),
          activeKey: 'admin.tour_orders'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.OFFICES,
          label: this.$t('nav.offices'),
          activeKey: 'admin.offices'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.OFFICE_PLANS,
          label: this.$t('nav.office_plans'),
          activeKey: 'admin.office_plans'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.ALL_USERS_LIST,
          label: this.$t('nav.admin_accounts'),
          activeKey: 'admin.admin_accounts'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.FURUSATO_PRODUCT,
          label: this.$t('nav.furusato_product_list'),
          activeKey: 'admin.furusato_product_list'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.FURUSATO_PRODUCT_CREATE,
          label: this.$t('nav.furusato_product'),
          activeKey: 'admin.furusato_product'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.FURUSATO_COUPON,
          label: this.$t('nav.furusato_coupon_list'),
          activeKey: 'admin.furusato_coupon_list'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.FURUSATO_COUPON_CREATE,
          label: this.$t('nav.furusato_coupon_create'),
          activeKey: 'admin.furusato_coupon'
        },
        {
          routeName: this.$consts.ROUTES.ADMIN.FURUSATO_COUPON_USE,
          label: this.$t('nav.furusato_coupon_use'),
          activeKey: 'admin.furusato_coupon_use'
        },
      ]
    }
  },
  methods: {
    clickLogout() {
      this.$auth.logout();
    }
  }
}
</script>
